import { createTheme, Shadows } from '@mui/material/styles';
import { fontFamilies } from 'utils';

const theme = createTheme({
    typography: {
        fontFamily: fontFamilies.GraphikRegular,
        fontSize: 15,
        allVariants: {
            color: 'black',
        },
    },
    shadows: Array(25).fill('none') as Shadows,
});

export const appTheme = createTheme(theme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
    },
});

export const tableTheme = createTheme(appTheme, {
    components: {
        MuiCustomBox: {
            styleOverrides: {
                root: {
                    padding: '28px 0px 28px 0px',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    lineHeight: 1,
                    paddingTop: '0px',
                    fontFamily: fontFamilies.GraphikMedium,
                    color: '#57626a',
                    borderBottom: '1px solid #74828D',
                },
                body: {
                    paddingTop: '16px',
                    verticalAlign: 'center',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#002B5A',
                    lineHeight: '22px',
                    borderBottom: '1px dashed #74828D',
                    borderRight: '2px solid white',
                },
                root: {
                    '&:not(:first-of-type)': {
                        paddingLeft: '0px',
                    },
                    fontSize: '15px',
                    paddingRight: '28px',
                    paddingBottom: '16px',
                    paddingLeft: '28px',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                item: {
                    justifyContent: 'space-between',
                    display: 'flex',
                },
            },
        },
        MuiTotalRowBox: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    paddingTop: '16px',
                },
            },
        },
    },
});

export const breakpointsTheme = createTheme({
    breakpoints: {
        values: {
            lg: 1200,
            md: 900,
            sm: 700,
            xl: 1536,
            xs: 0,
        },
    },
});

export const tooltipTheme = createTheme(breakpointsTheme, {
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: 'top',
            },
            styleOverrides: {
                tooltip: {
                    padding: '12px 24px 12px 24px',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#57626a',
                    fontSize: '14px',
                    backgroundColor: '#FFF',
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                },
                arrow: {
                    '&::before': {
                        color: '#FFF',
                    },
                },
            },
        },
    },
});
