import { ETFLinkButton } from 'components/ETFButton';
import { ETFCard, ItemDescription } from 'components/layout';
import Grid from 'components/layout/Grid';

import ETFModal from 'components/ETFModal';
import React, { useState } from 'react';

export default function FundDetail(props: { detail: string }) {
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => setOpenModal(false);
    const handleOpen = () => setOpenModal(true);

    const getCardDetails = (fundDetail: string): React.ReactNode => {
        return (
            <ItemDescription>
                {fundDetail.length <= 500 ? (
                    fundDetail
                ) : (
                    <>
                        {fundDetail.substring(0, fundDetail.lastIndexOf(' ', 500) + 1)}
                        <ETFLinkButton callback={handleOpen} text='... Read More' />
                        <ETFModal
                            openModal={openModal}
                            handleClose={handleClose}
                            labels={{ title: 'Fund Details', content: fundDetail }}
                        />
                    </>
                )}
            </ItemDescription>
        );
    };

    return (
        <ETFCard cardLabel='Fund Details'>
            <Grid item xs={12}>
                {getCardDetails(props.detail)}
            </Grid>
        </ETFCard>
    );
}
