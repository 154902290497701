import { createTheme, Paper, ThemeProvider, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ETFCard } from 'components/layout';
import { ETFTwoColumnGrid, Item, Value } from 'components/layout/ETFTwoColumnGrid';
import Grid from 'components/layout/Grid';
import { tooltipTheme } from 'components/themes/theme';
import * as React from 'react';
import {
    assetClasses,
    DataPointsDisplayNames,
    fontFamilies,
    formatValue,
    FormatValueParams,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNames,
    getDataPointsDisplayNameToFormattingType,
    SectionsNames as SiteSectionNames,
    ValueTypes,
} from 'utils';
import { EtfDetailsData } from '../types/research';

export default function KeyStatistic({ etfDetailsData }: { etfDetailsData: EtfDetailsData }) {
    const theme = createTheme(tooltipTheme, {
        components: {
            MuiTooltip: {
                defaultProps: {
                    placement: 'top-end',
                },
            },
        },
    });
    const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
    const isAboveSm = useMediaQuery(theme.breakpoints.up('sm'));

    // create enum with KeyStatistic sections names
    const enum SectionsNames {
        Trading = 'Trading',
        Performance = 'Performance',
        Flows = 'Flows',
        CreditQuality = 'Credit Quality',
    }
    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName(SiteSectionNames.KeyStatistic);
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType();
    // create matching between section name and data points names to show in section
    const dataPointsListDevidedBySections: Record<SectionsNames, Array<DataPointsDisplayNames>> = {
        [SectionsNames.Trading]: [
            DataPointsDisplayNames.Price,
            DataPointsDisplayNames.FiftyTwoWeekRange,
            DataPointsDisplayNames.PremiumDiscount,
            DataPointsDisplayNames.BidAskSpread,
            DataPointsDisplayNames.NetAssets,
            DataPointsDisplayNames.SharesOutstanding,
            DataPointsDisplayNames.OneMonthAverageVolume,
            DataPointsDisplayNames.NumberOfHoldings,
            DataPointsDisplayNames.Turnover,
        ],
        [SectionsNames.Performance]: [
            DataPointsDisplayNames.TwelveMonthYield,
            DataPointsDisplayNames.OneMonthReturn,
            DataPointsDisplayNames.ThreeMonthsReturn,
            DataPointsDisplayNames.YTDReturn,
            DataPointsDisplayNames.OneYearReturn,
        ],
        [SectionsNames.Flows]: [
            DataPointsDisplayNames.OneMonthFlows,
            DataPointsDisplayNames.ThreeMonthFlows,
            DataPointsDisplayNames.YTDFlows,
            DataPointsDisplayNames.OneYearFlows,
        ],
        [SectionsNames.CreditQuality]: [
            DataPointsDisplayNames.AverageCoupon,
            DataPointsDisplayNames.AverageDuration,
            DataPointsDisplayNames.PercentagesInvestmentGrade,
            DataPointsDisplayNames.PercentagesSpeculativeGrade,
        ],
    };
    // create instance of class FormatValueParams
    const formatValueParams = new FormatValueParams({
        source: etfDetailsData,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });
    // create function for creating input parameters for formatValue function using data point display name
    function createFormatValueParameters(key: DataPointsDisplayNames) {
        let result = formatValueParams.create({ key: key });
        // for FiftyTwoWeekRange data point we have special formatting
        if (key === DataPointsDisplayNames.FiftyTwoWeekRange) {
            result.value = 'FiftyTwoWeekRange';
            result = {
                ...result,
                additionalConfig: {
                    valueName: key,
                    high_split_dividend_adjusted_52_week: etfDetailsData.high_split_dividend_adjusted_52_week,
                    low_split_dividend_adjusted_52_week: etfDetailsData.low_split_dividend_adjusted_52_week,
                    listing_currency: etfDetailsData.listing_currency,
                },
            };
        }
        // put listing_currency for Currency values type
        if (dataPointsDisplayNameToFormattingType[key] === ValueTypes.Currency) {
            result = {
                ...result,
                additionalConfig: {
                    listing_currency: etfDetailsData.listing_currency,
                },
            };
        }

        return result;
    }

    const SubHeder = styled(Paper)(({ theme }) => ({
        fontSize: '15px',
        fontFamily: fontFamilies.GraphikMedium,
        color: '#57626a',
        paddingBottom: '18px',
    }));

    const sections: React.ReactNode[] = [];
    // get formatted value of assetClass
    const assetClass = formatValue(createFormatValueParameters(DataPointsDisplayNames.AssetClass));
    // fill sections list
    Object.keys(dataPointsListDevidedBySections).forEach(function (sectionName) {
        // show CreditQuality section only for Bonds asset class
        if (sectionName === SectionsNames.CreditQuality && assetClass !== assetClasses.Bonds) return;

        const sectionRows: any[] = [];
        // fill section rows
        dataPointsListDevidedBySections[sectionName as SectionsNames].forEach((dataPointName, index) => {
            // add section row
            sectionRows.push(
                <ThemeProvider theme={theme}>
                    <Tooltip title={dataPointName}>
                        <Item
                            sx={{
                                marginTop: index === 0 ? '0px' : '',
                                WebkitLineClamp: dataPointName.indexOf(' ') > -1 ? '' : '1', // anable one line elipsis, if no spaces
                            }}>
                            {dataPointName}
                        </Item>
                    </Tooltip>
                </ThemeProvider>,
            );
            const formattedValue = formatValue(createFormatValueParameters(dataPointName));
            sectionRows.push(
                <ThemeProvider theme={theme}>
                    <Tooltip title={formattedValue}>
                        <Value sx={{ marginTop: index === 0 ? '0px' : '' }}>{formattedValue}</Value>
                    </Tooltip>
                </ThemeProvider>,
            );
        });

        sections.push(
            <Grid container item>
                <Grid item>
                    <SubHeder>{sectionName}</SubHeder>
                </Grid>
                <ETFTwoColumnGrid>{React.Children.toArray(sectionRows)}</ETFTwoColumnGrid>
            </Grid>,
        );
    });

    let maxColumnHeight = 800;
    if (assetClass !== assetClasses.Bonds) {
        maxColumnHeight = 600;
    }

    return (
        <ETFCard cardLabel='Key Statistic'>
            <Grid
                item
                xs={isBelowMd && isAboveSm ? 5.7 : 12}
                container
                direction={'column'}
                maxHeight={isBelowMd && isAboveSm ? maxColumnHeight : undefined}
                columnSpacing={'36px'}
                rowSpacing={'36px'}>
                {React.Children.toArray(sections)}
            </Grid>
        </ETFCard>
    );
}
