import { useMediaQuery } from '@mui/material';
import { ETFDivider } from 'components/layout/ETFDivider';
import Grid from 'components/layout/Grid';
import ETFFlowsToAssetThemesAndFactorsChart from './charts/ETFFlowsToAssetThemesAndFactorsChart';
import ETFPerformanceThemesAndFactors from './charts/ETFPerformanceThemesAndFactors';
import KeyAssetClasses from './charts/KeyAssetClasses';
import SectorPerformance from './charts/SectorPerformance';
import OverviewCircle from 'assets/images/OverviewCircle.svg';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import { ContextType } from '../types/market';

export function MarketTrendsHome() {
    const isBelow1000px = useMediaQuery('(max-width:1000px)');

    const [, setTitle] = useOutletContext<ContextType>();

    useEffect(() => {
        setTitle('Market Trends');
    }, [setTitle]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ETFDivider
                    imageSource={OverviewCircle}
                    header='Overview'
                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                />
            </Grid>

            {isBelow1000px ? (
                <>
                    <Grid item xs={12}>
                        <ETFFlowsToAssetThemesAndFactorsChart />
                    </Grid>
                    <Grid item xs={12}>
                        <ETFPerformanceThemesAndFactors />
                    </Grid>
                    <Grid item xs={12}>
                        <KeyAssetClasses />
                    </Grid>
                    <Grid item xs={12}>
                        <SectorPerformance />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={6}>
                        <ETFFlowsToAssetThemesAndFactorsChart />
                    </Grid>
                    <Grid item xs={6}>
                        <ETFPerformanceThemesAndFactors />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyAssetClasses />
                    </Grid>
                    <Grid item xs={6}>
                        <SectorPerformance />
                    </Grid>
                </>
            )}
        </Grid>
    );
}
