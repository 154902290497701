import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { clientSideLogger } from 'lib/aws-rum-web-client';
import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
    children: React.ReactNode;
    removeTopMargin?: boolean;
};

export function ETFPageContainer({ children }: Props) {
    const location = useLocation();
    React.useEffect(() => {
        clientSideLogger.recordPageView(location.pathname);
    }, [location]);

    const MuiContainerStyles = {
        maxWidth: '1366px',
        backgroundColor: '#FFF',
        minHeight:'900px'
    };

    return (
        <Box sx={{ backgroundColor: '#f2f5fc' }}>
            <Container maxWidth={false} disableGutters sx={MuiContainerStyles}>
                {children}
            </Container>
        </Box>
    );
}
