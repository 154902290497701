import { ETFSearchByParams, getRequestQuery, UseData } from 'utils';
import {
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';

export function getKeyAssetClassesData({ cfraIds, tickerExchanges }: ETFSearchByParams) {
    return UseData<Array<KeyAssetClassesData>>({
        requestQuerry: getRequestQuery({ cfraIds, tickerExchanges }, 'performance/'),
        queryKey: ['getKeyAssetClassesData', `${JSON.stringify(cfraIds)}`, `${JSON.stringify(tickerExchanges)}`],
    });
}

export function getLevel2Flows({ top = 5, orderBy = 'flow_to_asset_ratio', sortDirection }: ETFSearchByParams) {
    return UseData<Array<ETFFlowsToAssetThemesAndFactorsData>>({
        requestQuerry: getRequestQuery({ top, orderBy, sortDirection }, 'category-level-2/flows/'),
        queryKey: ['getLevel2Flows', top, orderBy, sortDirection],
    });
}

export function getLevel2Performance({ top = 5, sortDirection, orderBy }: ETFSearchByParams) {
    return UseData<Array<ETFPerformanceThemesAndFactorsData>>({
        requestQuerry: getRequestQuery({ top, sortDirection, orderBy }, 'category-level-2/performance/'),
        queryKey: ['getLevel2Performance', top, sortDirection, orderBy],
    });
}
