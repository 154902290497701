import './App.scss';
import { AppProvider } from './providers/app';

function App() {
    return (
        <div className='App'>
            <AppProvider>
                <div />
            </AppProvider>
        </div>
    );
}

export default App;
