import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            width: 'auto',
            fontFamily: 'GraphikRegular',
            backgroundColor: '#fff',
            padding: '12px 24px 12px 24px',
            color: '#57626a',            
            radius:'5px',
            fontSize:'14px',
            boxShadow:  '0 3px 5px 2px rgba(38, 42, 46, 0.12)',
            "& .MuiTooltip-arrow": {
              "&::before": {
                backgroundColor: "#fff",
              }
            }
          },
        },       
      },      
    },
  });


const Tooltip = (props: TooltipProps) => {
return (
    <ThemeProvider theme={theme}>
<MUITooltip {...props} />
 </ThemeProvider>
);
};

export default Tooltip;


