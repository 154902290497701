import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ETFCloseButton } from 'components/ETFButton';
import Grid from 'components/layout/Grid';
import { fontFamilies } from 'utils';

const ModalBox = styled(Grid, {
    name: 'MuiModalBox',
    slot: 'Root',
    // We are specifying here how the styleOverrides are being applied based on props
    overridesResolver: (props, styles) => [styles.root],
})(({ theme }) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '49.7%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    borderBottom: 1,
    borderColor: '#EAEAEA',
    backgroundColor: '#fff',
    maxWidth: '1330px',
    padding: '36px',
    width: '95%',
    borderRadius: '5px',
    zIndex:1900
}));


export const ItemHeader = styled(Paper)(({ theme }) => ({
    fontSize: '38px',
    color: '#002B5A',
    lineHeight: '1',
    paddingBottom: '36px',
    fontFamily: fontFamilies.GraphikMedium,
}));

export const ItemDescription = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    color: '#57626a',
    fontFamily: fontFamilies.GraphikRegular,
    lineHeight: '26px',
}));

type Props = {
    openModal: boolean;
    handleClose: any;
    labelStyle?: object;
    labels: {
        title: string;
        content: string;
    };
};

export default function ETFModal({ openModal, handleClose, labelStyle, labels }: Props) {
    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                BackdropProps={{ style: { backgroundColor: '#002B5A', opacity: 0.33 } }}>
                <ModalBox container alignItems='baseline'>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ItemHeader sx={labelStyle}>{labels.title}</ItemHeader>
                        <ETFCloseButton onClick={handleClose} />
                    </Grid>
                    <Grid item xs={12}>
                        <ItemDescription>{labels.content}</ItemDescription>
                    </Grid>
                </ModalBox>
            </Modal>
        </div>
    );
}
