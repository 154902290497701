import { ETFSearchByParams, getRequestQuery, UseData } from 'utils';
import {
    CreditExposureHistoricalData,
    ETFDataFlows,
    EtfDataFlowsAndPerformance,
    EtfDataHoldingsAndExposure,
    EtfDetailsData,
    SectorExposureHistoricalData,
} from '../types/research';

export function getEtfDataOverview({ id, ticker, exchange }: ETFSearchByParams) {
    return UseData<EtfDetailsData>({
        requestQuerry: getRequestQuery({ id, ticker, exchange }),
        queryKey: ['getEtfDataOverview', id, ticker, exchange],
    });
}

export function getEtfDataHoldingsAndExposure({ cfraId, ticker, exchange, top = 10 }: ETFSearchByParams) {
    return UseData<EtfDataHoldingsAndExposure>({
        requestQuerry: getRequestQuery({ cfraId, ticker, exchange, top }, 'top-holdings/'),
        queryKey: ['etfDataHoldingsAndExposure', cfraId, ticker, exchange, top],
    });
}

export function getEtfDataFlowsAndPerformance({ cfraId, ticker, exchange }: ETFSearchByParams) {
    return UseData<EtfDataFlowsAndPerformance>({
        requestQuerry: getRequestQuery({ cfraId, ticker, exchange }, 'performance/'),
        queryKey: ['etfDataFlowsAndPerformance', cfraId, ticker, exchange],
    });
}

export function getSectorExposureHistorical({ cfraId, ticker, exchange, numberOfYears = 5 }: ETFSearchByParams) {
    return UseData<SectorExposureHistoricalData>({
        requestQuerry: getRequestQuery({ cfraId, ticker, exchange, numberOfYears }, 'sector-exposure-history/'),
        queryKey: ['getSectorExposureHistorical', cfraId, ticker, exchange, numberOfYears],
    });
}

export function getEtfHoldingsAndExposure<T>({ dataType, cfraId }: { cfraId: string; dataType: string }) {
    const requestQuerry = getRequestQuery({ cfraId }, `${dataType}/`);
    const queryKey = ['getEtfHoldingsAndExposure', `cfraId ${cfraId}`, `dataType ${dataType}`];

    return UseData<T>({
        requestQuerry: requestQuerry,
        queryKey: queryKey,
    });
}

export function getCreditExposureHistorical({ cfraId, ticker, exchange, numberOfYears = 5 }: ETFSearchByParams) {
    return UseData<CreditExposureHistoricalData>({
        requestQuerry: getRequestQuery({ cfraId, ticker, exchange, numberOfYears }, 'credit-exposure-history/'),
        queryKey: ['getCreditExposureHistorical', cfraId, ticker, exchange, numberOfYears],
    });
}

export function getETFFlows({ cfraId, ticker, exchange, dateRange }: ETFSearchByParams) {
    return UseData<Array<ETFDataFlows>>({
        requestQuerry: getRequestQuery({ cfraId, ticker, exchange, dateRange }, 'flows/'),
        queryKey: ['getETFFrowsFor', cfraId, ticker, exchange, dateRange],
    });
}
