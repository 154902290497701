import { Box, SvgIcon, ThemeProvider } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { tooltipTheme } from 'components/themes/theme';
import React from 'react';
import { ButtonProps } from './types';

type ETFIconButtonProps = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} & ButtonProps;

type CustomIconButtonProps = {
    widthheightsize?: number; // lowercase to avoid Warning: React does not recognize the `widthHeightSize` prop on a DOM element.
} & IconButtonProps;

const CustomIconButton = styled(IconButton)<CustomIconButtonProps>(({ widthheightsize }) => ({
    width: widthheightsize,
    height: widthheightsize,
}));

const CustomIcon = styled(({ Icon, onClick, viewBoxSize, borderRadius, ...props }: ETFIconButtonProps) => (
    <SvgIcon component={Icon} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`} fontSize={'inherit'} {...props} /> // need {...props} to make styles for disabled property work
))<ETFIconButtonProps>(({ disabled, borderRadius }) => ({
    color: disabled ? '#B1BCC9' : '#4375BB',
    '&:hover': {
        color: '#ffffff',
        backgroundColor: '#007BB8',
    },
    borderRadius: borderRadius,
}));

export function ETFIconButton({
    name,
    Icon,
    tooltipText = '', // need default value to avoid error in case if no tooltip - Type 'string | undefined' is not assignable to type 'NonNullable<ReactNode>'
    disabled,
    onClick,
    buttonFontSize,
    viewBoxSize = 40,
    borderRadius = 4,
    ...props
}: ETFIconButtonProps) {
    return (
        <ThemeProvider theme={tooltipTheme}>
            <Box {...props}>
                {/*need wrap into Box for setting up styles like padding*/}
                <Tooltip title={tooltipText} disableHoverListener={disabled || !tooltipText}>
                    <Box>
                        {/*need to wrap button into Box to avoid "You are providing a disabled `button` child to the Tooltip component." warning*/}
                        {/*disabled makes button button disabled, but don't influence on the button color*/}
                        {/*when ETFMenu used - the onClick property overwrites by ETFMenu component*/}
                        <CustomIconButton
                            onClick={onClick}
                            disabled={disabled}
                            widthheightsize={buttonFontSize}
                            sx={{ fontSize: buttonFontSize }}
                            aria-label={name}>
                            {/*disabled used for icon styles*/}
                            <CustomIcon
                                Icon={Icon}
                                disabled={disabled}
                                viewBoxSize={viewBoxSize}
                                borderRadius={borderRadius}
                            />
                        </CustomIconButton>
                    </Box>
                </Tooltip>
            </Box>
        </ThemeProvider>
    );
}
