import { ThemeProvider, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { exportExcel } from 'components/excelExport/export';
import { ExcelCustomFields, ExcelMetadata, ExcelTable } from 'components/excelExport/type';
import { ETFCard, ETFInfo } from 'components/layout';
import { ItemHeader, TotalRowBox } from 'components/layout/ETFCard';
import { OverflowElipsis } from 'components/layout/ETFTwoColumnGrid';
import Grid from 'components/layout/Grid';
import { ColumStyle } from 'components/layout/types';
import { tableTheme, tooltipTheme } from 'components/themes/theme';
import * as React from 'react';
import {
    assetClasses,
    DataPointsDisplayNames,
    formatValue,
    FormatValueParams,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNames,
    getDataPointsDisplayNameToFormattingType,
    SectionsNames,
    ValueTypes,
} from 'utils';
import { getEtfDataHoldingsAndExposure } from '../api/etfDetailsData';
import { EtfDataHoldingsAndExposureRow, EtfDetailsData } from '../types/research';

const informationLabel = {
    title: 'Information',
    content:
        'The sum of weights for top holdings can exceed 100% in some cases e.g. when an ETF has derivatives exposure or has both long & short positions. Such ETFs will have some constituents with negative weights, and the sum of weights will always be 100%.',
};

export default function TopHoldings({ etfDetailsData, cfraId }: { etfDetailsData: EtfDetailsData; cfraId: string }) {
    // set number of holdings to get from API and show in UI
    const top: number = 10;
    // getting UseQueryResult object with data for etfDataHoldingsAndExposure
    const etfDataHoldingsAndExposureQueryResult = getEtfDataHoldingsAndExposure({
        cfraId: cfraId,
        top: top,
    });
    // show card loading if data still loading
    if (etfDataHoldingsAndExposureQueryResult.isLoading) {
        return <ETFCard isLoading={etfDataHoldingsAndExposureQueryResult.isLoading} />;
    }
    // Show Nothing if no data
    if (!etfDataHoldingsAndExposureQueryResult.data) return null;
    // cut off UseQueryResult attributes, extract only etfDataHoldingsAndExposure data
    const etfDataHoldingsAndExposure = etfDataHoldingsAndExposureQueryResult.data;
    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();
    // create matching between asset class and data points to show in UI
    const assetClassToDataPoints: Record<assetClasses, Array<DataPointsDisplayNames>> = {
        [assetClasses.EquitiesStocks]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.Ticker,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
            DataPointsDisplayNames.YTDReturn,
            DataPointsDisplayNames.Sector,
        ],
        [assetClasses.Bonds]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.Cusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
            DataPointsDisplayNames.MaturityDate,
            DataPointsDisplayNames.Coupon,
        ],
        [assetClasses.CommoditiesAndMetals]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
        [assetClasses.TargetDateMultiAsset]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
        [assetClasses.Currency]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
        [assetClasses.OtherAssetTypes]: [
            DataPointsDisplayNames.Name,
            DataPointsDisplayNames.TickerCusip,
            DataPointsDisplayNames.PercentageOfETFAssets,
            DataPointsDisplayNames.ConstituentType,
        ],
    };
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName(SectionsNames.TopHoldings);
    // create matching data point name and column width for specific asset class
    const assetClassTodataPointNameToCellStyle: Record<assetClasses, { [key in DataPointsDisplayNames]?: ColumStyle }> =
        {
            [assetClasses.EquitiesStocks]: {
                [DataPointsDisplayNames.Name]: { width: '35%' },
                [DataPointsDisplayNames.Cusip]: { width: '10%' },
                [DataPointsDisplayNames.PercentageOfETFAssets]: { width: '10%' },
            },
            [assetClasses.Bonds]: {
                [DataPointsDisplayNames.Name]: { width: '45%' },
                [DataPointsDisplayNames.Cusip]: { width: '10%' },
                [DataPointsDisplayNames.PercentageOfETFAssets]: { width: '10%' },
                [DataPointsDisplayNames.MaturityDate]: { width: '10%' },
            },
            [assetClasses.CommoditiesAndMetals]: {
                [DataPointsDisplayNames.Name]: { width: '50%' },
            },
            [assetClasses.Currency]: {
                [DataPointsDisplayNames.Name]: { width: '45%' },
            },
            [assetClasses.OtherAssetTypes]: {
                [DataPointsDisplayNames.Name]: { width: '45%' },
            },
            [assetClasses.TargetDateMultiAsset]: {
                [DataPointsDisplayNames.Name]: { width: '50%' },
            },
        };

    const displayShortNames: { [k in DataPointsDisplayNames]?: string } = {
        [DataPointsDisplayNames.ConstituentType]: 'Type',
        [DataPointsDisplayNames.PercentageOfETFAssets]: '% of ETF',
        [DataPointsDisplayNames.MaturityDate]: 'Maturity',
    };
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType(SectionsNames.TopHoldings);
    // get total row template
    const totalRowText: string = DataPointsDisplayNames.TotalsRow.replace('{top}', top.toString());
    // get total row value
    const totalRowValue = formatValue({
        value: etfDataHoldingsAndExposure.total_weight_percentage,
        formattingType: dataPointsDisplayNameToFormattingType[DataPointsDisplayNames.TotalsRow],
    });

    const tableRows: any = [];
    // we need to prepare data for export. All pages have different structure. Must mimicrate their logic.
    const exportData: any[][] = [];
    const exportColumns = new Map<string, ValueTypes>();
    // loop through all table rows data from API and fill table rows object

    etfDataHoldingsAndExposure.holdings.forEach((row: EtfDataHoldingsAndExposureRow, rowIndex: number) => {
        const tableCells: any = [];
        const exportCells: any = [];
        // loop through all data points for this asset class
        assetClassToDataPoints[etfDetailsData.asset_class].forEach((key) => {
            // create instance of FormatValueParams class
            const formatValueParams = new FormatValueParams({
                source: row,
                dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
                dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
            });
            // create parameters for formatValue function to get the data point value placed in key variable
            let formatValueParameters = formatValueParams.create({ key: key });
            // data processing for export
            if (dataPointDisplayNameToFieldName[key]) {
                if (rowIndex === 0) {
                    exportColumns.set(key, formatValueParameters.formattingType);
                }
                exportCells.push(formatValueParameters.value);
            }
            // for data point MaturityDate set dateFormat
            if (key === DataPointsDisplayNames.MaturityDate)
                formatValueParameters = {
                    ...formatValueParameters,
                    ...{
                        additionalConfig: {
                            dateFormat: 'MM/DD/YYYY',
                        },
                    },
                };
            // for data point Coupon set parameter showNumberIfZero - formatter will return 0 if value === 0 (usually it returns dash in this case)
            if (key === DataPointsDisplayNames.Coupon) {
                formatValueParameters = {
                    ...formatValueParameters,
                    showNumberIfZero: true,
                };
            }
            // get formatted value
            let formatedValue = formatValue(formatValueParameters);
            // for the data point TickerCusip apply logic - show Ticker value if it is available
            // otherwise show Cusip value if it is available
            // otherwise show dash
            if (key === DataPointsDisplayNames.TickerCusip) {
                formatedValue = formatValue(formatValueParams.create({ key: DataPointsDisplayNames.Ticker }));
                if (formatedValue === '-') {
                    formatedValue = formatValue(formatValueParams.create({ key: DataPointsDisplayNames.Cusip }));
                }
            }
            // fill tableCells list with table cell filled with formatted value
            tableCells.push(
                <TableCell>
                    {[DataPointsDisplayNames.Name, DataPointsDisplayNames.ConstituentType].includes(key) ? (
                        <ThemeProvider theme={tooltipTheme}>
                            <Tooltip title={formatedValue}>
                                <OverflowElipsis sx={{ textTransform: 'uppercase', WebkitLineClamp: '1' }}>
                                    {formatedValue}
                                </OverflowElipsis>
                            </Tooltip>
                        </ThemeProvider>
                    ) : (
                        formatedValue
                    )}
                </TableCell>,
            );
        });

        exportData.push(exportCells);
        // tableRows with TableRow element with tableCells inside
        tableRows.push(<TableRow>{React.Children.toArray(tableCells)}</TableRow>);
    });

    const excelTable: ExcelTable = {
        data: exportData,
        columns: exportColumns,
        columnStyles: assetClassTodataPointNameToCellStyle[etfDetailsData.asset_class],
    };

    const excelMetadata: ExcelMetadata = {
        cardName: SectionsNames.TopHoldings,
        ticker: etfDetailsData.ticker,
        etfName: etfDetailsData.composite_name,
    };

    const customFields: ExcelCustomFields = {
        data: [[`${totalRowText} ${totalRowValue}`]],
        fieldStyle: [[{ font: { bold: true } }]],
    };

    return (
        <ThemeProvider theme={tableTheme}>
            <ETFCard>
                <Grid item xs={12} sx={{ paddingLeft: '28px', paddingRight: '28px' }}>
                    <ItemHeader>{SectionsNames.TopHoldings}</ItemHeader>
                    <TableExportMenus exportCallback={() => exportExcel(excelTable, excelMetadata, customFields)} />
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label='top holdings table'>
                        <TableHead>
                            <TableRow>
                                {assetClassToDataPoints[etfDetailsData.asset_class].map(
                                    (displayName: DataPointsDisplayNames, index: number) => (
                                        <TableCell
                                            key={index}
                                            sx={
                                                assetClassTodataPointNameToCellStyle[etfDetailsData.asset_class][
                                                    displayName
                                                ]
                                            }>
                                            {displayShortNames[displayName] || displayName}
                                        </TableCell>
                                    ),
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>{React.Children.toArray(tableRows)}</TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} sx={{ paddingLeft: '28px' }}>
                    <TotalRowBox>
                        <Grid
                            item
                            sx={{
                                alignItems: 'center',
                            }}>
                            {totalRowText}:&nbsp;
                            {etfDataHoldingsAndExposure.total_weight_percentage > 1 ? (
                                <ETFInfo labels={informationLabel} />
                            ) : (
                                totalRowValue
                            )}
                            <div style={{ flexGrow: 1 }} />
                        </Grid>
                    </TotalRowBox>
                </Grid>
            </ETFCard>
        </ThemeProvider>
    );
}
