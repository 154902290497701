import { ICellRendererParams } from 'ag-grid-community';
import { AgGrid, columnDefs, getAgGridFormatter } from 'components/DataGrid/AgGrid';
import { ETFCard, ItemHeader } from 'components/layout';
import { getKeyAssetClassesData, getLevel2Flows, getLevel2Performance } from 'features/marketTrends/api/market';
import { ETFFlowsToAssetThemesAndFactorsData, KeyAssetClassesData } from 'features/marketTrends/types/research';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { useOutletContext } from 'react-router-dom';
import { ValueTypes } from 'utils/valuesFormatter';
import { ContextType, MarketTrend, MarketTrendsDetailsProps } from '../types/market';
import ETFFlowsToAssetThemesAndFactorsChart from './charts/ETFFlowsToAssetThemesAndFactorsChart';
import ETFPerformanceThemesAndFactors from './charts/ETFPerformanceThemesAndFactors';
import KeyAssetClasses, {
    tickerDisplayName as keyAssetClassesTickerDisplayName,
    tickerExchanges as keyAssetClassesTickerExchanges
} from './charts/KeyAssetClasses';
import SectorPerformance, {
    tickerDisplayName as SectorPerformanceTickerDisplayName,
    tickerExchanges as SectorPerformanceTickerExchanges
} from './charts/SectorPerformance';

export function MarketTrendsDetails({ label }: MarketTrendsDetailsProps) {
    const [, setTitle] = useOutletContext<ContextType>();

    useEffect(() => {
        setTitle(label);
    }, [setTitle, label]);

    let columnDefs: columnDefs = null;

    let result: UseQueryResult<any[]>;

    const getRenderer = (type: 'cfraId' | 'ticker/exchange') => (props: ICellRendererParams) => {
        const value = props.valueFormatted || props.value;
        return (
            <a
                href={
                    type === 'cfraId'
                        ? `/etf/id/${props.data.cfra_id || props.data.id}`
                        : `/etf/ticker/${props.data.composite_ticker}/exchange/${props.data.exchange_code}`
                }
                target={'_blank'}
                rel='noreferrer'>
                {value}
            </a>
        );
    };

    function getMinWidthForHeader(headerName?: string): number {
        switch (headerName) {
            case 'YTD Return':
                return 125;
            case '1 Month Return':
            case '1 Year Return':
            case '1 Month Flows':
            case '1 Year Flows':
            case 'Sector':
                return 130;
            case '3 Month Return':
            case '3 Month Flows':
                return 145;
            case 'CFRA Category 2':
                return 150;
            case '3 Month Flows to Asset':
                return 175;
            default:
                return 120;
        }
    }

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            result = getLevel2Flows({ sortDirection: 'desc', top: 1000 });

            columnDefs = [
                { headerName: 'CFRA Category 2', field: 'level_2_name' },
                {
                    headerName: '3 Month Flows',
                    field: 'net_flows_three_month',
                    valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
                    type: 'rightAligned',
                },
                {
                    headerName: 'Total Assets',
                    field: 'total_net_assets',
                    valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Flows to Asset',
                    field: 'flow_to_asset_ratio',
                    sort: 'desc',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '# Of ETFs',
                    field: 'number_of_etfs',
                    type: 'rightAligned',
                    flex: 1,
                },
            ];
            break;
        }
        case MarketTrend.PerformanceDetails: {
            result = getLevel2Performance({ sortDirection: 'desc', top: 1000 });
            const renderer = getRenderer('cfraId');

            columnDefs = [
                {
                    headerName: 'CFRA Category 2',
                    field: 'level_2_name',
                },
                {
                    headerName: 'ETF Name',
                    field: 'composite_name',
                    cellRenderer: renderer,
                },
                {
                    headerName: 'Ticker',
                    field: 'composite_ticker',
                    cellRenderer: renderer,
                },
                {
                    headerName: '3 Month Return',
                    field: 'return_split_and_dividend_three_month',
                    sort: 'desc',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '# Of ETFs',
                    field: 'number_of_etfs',
                    type: 'rightAligned',
                    flex: 1,
                },
            ];
            break;
        }
        case MarketTrend.KeyAssetClassesDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: keyAssetClassesTickerExchanges,
            });
            const renderer = getRenderer('cfraId');

            columnDefs = [
                {
                    headerName: 'Asset Class',
                    field: 'asset_class',
                    sort: 'asc',
                },
                { headerName: 'ETF Name', field: 'composite_name', cellRenderer: renderer },
                { headerName: 'Ticker', field: 'composite_ticker', cellRenderer: renderer },
                {
                    headerName: 'YTD Return',
                    field: 'performance.split_and_dividend_ytd_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Month Return',
                    field: 'performance.month_1_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Return',
                    field: 'performance.month_3_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Return',
                    field: 'performance.return_split_and_dividend_one_year',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                { headerName: 'YTD Flows', field: 'tbd', type: 'rightAligned' },
                {
                    headerName: '1 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
            ];

            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: SectorPerformanceTickerExchanges,
            });
            const renderer = getRenderer('ticker/exchange');

            columnDefs = [
                { headerName: 'Sector', field: 'sector', sort: 'asc' },
                { headerName: 'ETF Name', field: 'composite_name', cellRenderer: renderer },
                { headerName: 'Ticker', field: 'composite_ticker', cellRenderer: renderer },
                {
                    headerName: 'YTD Return',
                    field: 'performance.split_and_dividend_ytd_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Month Return',
                    field: 'performance.month_1_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Return',
                    field: 'performance.month_3_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Return',
                    field: 'performance.return_split_and_dividend_one_year',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                { headerName: 'YTD Flows', field: 'tbd', type: 'rightAligned' },
                {
                    headerName: '1 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
            ];

            break;
        }
        default: {
            throw new Error(`${label} doesn't exist.`);
        }
    }

    if (result.isLoading) {
        return <ETFCard isLoading={result.isLoading} />;
    }

    if (!result.data) return null;

    let rowsData: Array<any>;

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            rowsData = (result.data as ETFFlowsToAssetThemesAndFactorsData[]).map((row) => ({
                ...row,
                number_of_etfs: 'TBD',
            }));
            break;
        }
        case MarketTrend.PerformanceDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                number_of_etfs: 'TBD',
            }));
            break;
        }
        case MarketTrend.KeyAssetClassesDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                tbd: 'TBD',
                asset_class: keyAssetClassesTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                tbd: 'TBD',
                sector: SectorPerformanceTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        default: {
            throw new Error(`${label} doesn't exist.`);
        }
    }

    const charts: Record<MarketTrend, JSX.Element | null> = {
        [MarketTrend.FlowsToAssetsDetails]: <ETFFlowsToAssetThemesAndFactorsChart details={false} />,
        [MarketTrend.PerformanceDetails]: <ETFPerformanceThemesAndFactors details={false} />,
        [MarketTrend.KeyAssetClassesDetails]: <KeyAssetClasses details={false} />,
        [MarketTrend.SectorPerformanceDetails]: <SectorPerformance details={false} />,
    };

    const labelToNumberOfRowsToShow: Partial<Record<MarketTrend, number>> = {
        [MarketTrend.FlowsToAssetsDetails]: 20,
        [MarketTrend.PerformanceDetails]: 20,
    };

    return (
        <ETFCard>
            {charts[label]}
            <ItemHeader sx={{ paddingTop: '20px' }}>{label}</ItemHeader>
            <AgGrid
                columnDefs={columnDefs!.map((obj) => ({
                    ...obj,
                    minWidth: getMinWidthForHeader(obj.headerName),
                }))}
                rowsData={rowsData}
                numberOfRowsToShow={labelToNumberOfRowsToShow[label]}
            />
        </ETFCard>
    );
}
