import { ColumStyle } from 'components/layout/types';
import { Column, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { getPercentageValue, todayDateStringUS, ValueTypes } from 'utils';
import { ExcelCustomFields, ExcelMetadata, ExcelTable, TableProps } from './type';

export const setDisclaimer = (workbook: Workbook) => {

    let worksheet = workbook.addWorksheet('Disclaimer');

    worksheet.addRows([
        [
            ' S&P GLOBAL™ is used under license. The owner of this trademarks is S&P Global Inc. or its affiliate, ' +
                'which are not affiliated with CFRA Research or the author of this content. Copyright ©  2022 CFRA. All' +
                ' rights reserved. Certain information is copyright ©2022, S&P Global Market Intelligence LLC (and its a' +
                'ffiliates as applicable). All rights reserved. Because of the possibility of human or mechanical error,' +
                ' any data contained herein is provided "AS IS" and CFRA does not guarantee the accuracy, adequacy, comple' +
                'teness or availability of any information and is not responsible for any errors or omissions  or for the ' +
                'results obtained from  the use of such information. CFRA GIVES NO EXPRESS OR IMPLIED WARRANTIES OF ANY KI' +
                'ND, INCLUDING,  BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE' +
                ' OR USE. IN NO EVENT SHALL CFRA BE LIABLE TO ANY PERSON OR ENTITY FOR ANY LIABILITY  WHATSOEVER  OR ANY D' +
                'IRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR RESULTING  FROM ANY USE HEREOF. R' +
                'EPRODUCTION OF ANY DATA CONTAINED HEREIN IN ANY FORM AND/OR DISSEMINATION,  DISTRIBUTION, RESALE OR STORA' +
                'GE FOR SUBSEQUENT USE OF ANY SUCH PURPOSE BY ANY MEANS,  WHATSOEVER, OF ANY DATA CONTAINED HEREIN, IN WHO' +
                'LE OR IN PART, IS STRICTLY PROHIBITED EXCEPT  WITH THE PRIOR WRITTEN PERMISSION OF CFRA.',
        ],
    ]);

    const nameCol = worksheet.getColumn('A');
    nameCol.width = 115;
    nameCol.alignment = { wrapText: true };
    return workbook;
};

function getTableColumns(columns: Map<string, ValueTypes>) {
    let columnsToExport: any[] = [];
    columns.forEach((type: ValueTypes, column: string) => {
        columnsToExport.push({ name: column, filterButton: false });
    });
    return columnsToExport;
}

function getBlankRowNumber(ws: Worksheet, marginBottomRows: number = 3): number {
    return ws.rowCount + marginBottomRows;
}

function setCustomFields(ws: Worksheet, customFields: ExcelCustomFields) {
    let firstBlankRow: number = getBlankRowNumber(ws);
    for (var i = 0; i < customFields.data.length; i++) {
        let row = ws.getRow(firstBlankRow);
        for (var j = 0; j < customFields.data[i].length; j++) {
            let cell = row.getCell(j + 1);
            cell.value = customFields.data[i][j];
            if (customFields.fieldStyle && customFields.fieldStyle[i][j]) {
                if (customFields.fieldStyle[i][j].font) cell.font = customFields.fieldStyle[i][j].font!;
                if (customFields.fieldStyle[i][j].alignment) cell.alignment = customFields.fieldStyle[i][j].alignment!;
            }
        }
        firstBlankRow++;
    }
}

function getHeaderAddresses(ws: Worksheet, tableRowRef: number) {
    let headerAddresses: { [columnName: string]: string } = {};
    const row = ws.getRow(tableRowRef);

    row.eachCell({ includeEmpty: true }, function (cell) {
        headerAddresses[String(cell.value)] = cell.address.replace(/[^a-zA-Z]+/g, ''); // ignore row number
    });

    return headerAddresses;
}

function setWidth(column: Column, numberOfColumns: number, style?: ColumStyle, maxWidth: number = 155) {
    if (style) {
        column.width = maxWidth * getPercentageValue(style.width);
    } else {
        column.width = maxWidth / numberOfColumns;
    }
}

function setAlignment(column: Column, type: ValueTypes) {
    switch (type) {
        case ValueTypes.Numeral: {
            column.alignment = { horizontal: 'right' };
            break;
        }
        case ValueTypes.Percentage: {
            column.alignment = { horizontal: 'right' };
            break;
        }
        case ValueTypes.ConvertedPercentage: {
            column.alignment = { horizontal: 'right' };
            break;
        }
        default: {
            column.alignment = { horizontal: 'left' };
            break;
        }
    }
}

function setType(column: Column, type: ValueTypes) {
    switch (type) {
        case ValueTypes.Numeral: {
            column.numFmt = '0.00';
            break;
        }
        case ValueTypes.Percentage: {
            column.numFmt = '0.00%';
            break;
        }
        case ValueTypes.ConvertedPercentage: {
            column.numFmt = '#,#0.00"%"';
            break;
        }
        default: {
            break;
        }
    }
}

function formatColumnStyle(
    ws: Worksheet,
    headerAddresses: { [columnName: string]: string },
    columns: Map<string, ValueTypes>,
    columnStyles?: { [id: string]: ColumStyle },
) {
    let numberOfColumns: number = Object.keys(headerAddresses).length;
    Object.entries(headerAddresses).forEach(([columnName, addr]) => {
        const column = ws.getColumn(addr);
        const columnType = columns.get(columnName);

        setWidth(column, numberOfColumns, columnStyles?.[columnName]);
        if (columnType) {
            setAlignment(column, columnType);
            setType(column, columnType);
        }
    });
}

function setDataTable(ws: Worksheet, table: ExcelTable, colRef: string = 'A', header: boolean = true): TableProps {
    const tableRowRef: number = getBlankRowNumber(ws);
    // create table
    ws.addTable({
        name: 'Table',
        ref: `${colRef}${tableRowRef}`,
        headerRow: header, // [BUG] can't be false https://github.com/exceljs/exceljs/issues/1615
        totalsRow: false,
        style: {
            theme: 'TableStyleLight1', // [BUG] can't be null
            showRowStripes: false,
        },
        columns: getTableColumns(table.columns), // add column properties
        rows: table.data,
    });

    let props: TableProps = {
        colRef: 'A',
        tableStart: header ? tableRowRef + 1 : tableRowRef,
        tableEnd: ws.rowCount,
        headerAddresses: header ? getHeaderAddresses(ws, tableRowRef) : {},
    };

    return props;
}

function notEmptyArray(element: any[]) {
    return element.length > 0;
}

function setMetadata(ws: Worksheet, metadata: ExcelMetadata) {
    const metadataView: any[][] = [
        ['CFRA ETF Insights'],
        [metadata.cardName],
        metadata.etfName ? ['ETF Name:', metadata.etfName] : [],
        metadata.ticker ? ['Ticker:', metadata.ticker] : [],
        ['Download Date:', todayDateStringUS()],
    ].filter(notEmptyArray);
    ws.addRows(metadataView);
}

export const downloadXLSX = (workbook: Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    });
};

export const exportExcel = async (table: ExcelTable, metadata: ExcelMetadata, customFields?: ExcelCustomFields) => {
    // Since the Library size is so large, we should only download when requested
    // eslint-disable-next-line
    import('exceljs').then(async (ExcelJS) => {
        // Create workbook
        const workbook = new ExcelJS.Workbook();
        // Add Data Worksheet
        const worksheet = workbook.addWorksheet(metadata.cardName);
        // set metadata information
        setMetadata(worksheet, metadata);
        // create table with data
        let tableProps: TableProps = setDataTable(worksheet, table);
        // styles for table
        formatColumnStyle(worksheet, tableProps.headerAddresses, table.columns, table.columnStyles);
        // custom logic here
        if (customFields) setCustomFields(worksheet, customFields);
        // add disclaimer sheet
        setDisclaimer(workbook);
        // Export to Excel
        downloadXLSX(workbook, `${metadata.cardName}${metadata.ticker ? ` ${metadata.ticker}` : ''}`);
    });
};
