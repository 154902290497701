export const robotoFont = 'Roboto, sans-serif';

export const enum fontWeights {
    Thin = '100', // the same as Hairline
    ExtraLight = '200', // the same as Ultra Light
    Light = '300',
    Regular = '400', // the same as Normal
    Medium = '500',
    SemiBold = '600', // the same as Demi Bold
    Bold = '700',
    ExtraBold = '800', // the same as Ultra Bold
    Black = '900', // the same as Heavy
    ExtraBlack = '950', // the same as Ultra Black
}

export const enum fontFamilies {
    GraphikLight = 'GraphikLight',
    GraphikMedium = 'GraphikMedium',
    GraphikRegular = 'GraphikRegular',
}
