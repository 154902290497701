import Box from '@mui/material/Box';
import Skeleton from 'components/layout/Skeleton';
import { useParams } from 'react-router-dom';
import { getEtfDataOverview } from '../api/etfDetailsData';
import ETFDetailsData from '../components/EtfDetailsData';
import ETFDetailTabs from '../components/ETFDetailsTabs';
import { ETFDetailsParams } from '../types/research';

export function ETF() {
    const { ticker, exchange, id } = useParams<keyof ETFDetailsParams>() as ETFDetailsParams;

    // Why we send parameters as dictionary?
    const latestUpdatesQuery = getEtfDataOverview({ ticker: ticker, exchange: exchange, id: id });

    // show loading if data still loading
    if (latestUpdatesQuery.isLoading) {
        return (
            <Box sx={{ minHeight: 1000 }}>
                <Skeleton />
            </Box>
        );
    }

    // Show Nothing if no data
    if (!latestUpdatesQuery || !latestUpdatesQuery.data) return null;

    return (
        <>
            <ETFDetailsData etfDetailsData={latestUpdatesQuery.data} />
            <ETFDetailTabs
                latestUpdatesQuery={latestUpdatesQuery.data}
                companyData={{ ticker: ticker, exchange: exchange }}
            />
        </>
    );
}
export default ETF;
