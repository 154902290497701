import { getMinAndMaxTick, getTickIntervalFrom } from 'components/Chart/Chart';
import { formatFlowsNumber, getDateForTooltipFormatter, StyledFlowsChart } from 'components/Chart/Flows';
import { getTooltipHTML } from 'components/Chart/Options';
import { DateValuePair } from 'components/Chart/types';
import { ETFCard, ETFEmptyCard, TotalRowBox } from 'components/layout';
import Grid from 'components/layout/Grid';
import Highcharts from 'highcharts/highstock';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { DateRanges, getMomentObjectFrom, getSumOfAllValues } from 'utils';
import { getETFFlows } from '../api/etfDetailsData';
import { ETFDataAllRanges, ETFDataAllRangesQueryResult, ETFDataFlows, ETFFlowsProps } from '../types/research';

type ETFFlowsChartData = {
    xAxisDates: Array<Moment>;
    categoriesData: Array<DateValuePair>;
};

const getTotalRow = (periodSelected: DateRanges, categoriesData: Array<number | null>): JSX.Element => {
    const periodSelectedToDisplayRepresentation: Record<DateRanges, string> = {
        [DateRanges.OneWeek]: '1 Week',
        [DateRanges.OneMonth]: '1 Month',
        [DateRanges.ThreeMonth]: '3 Month',
        [DateRanges.SixMonth]: '6 Month',
        [DateRanges.OneYear]: '1 Year',
        [DateRanges.ThreeYears]: '3 Years',
        [DateRanges.FiveYears]: '5 Years',
    };

    const totalRowText = `${periodSelectedToDisplayRepresentation[periodSelected]} Net Flows: `;
    const totalRowValue = formatFlowsNumber({
        value: getSumOfAllValues(categoriesData),
        skipSpaceBetweenDollarAndNumber: true,
    })
        .replace('M', 'millions')
        .replace('K', 'thousands');

    return (
        <TotalRowBox>
            {totalRowText} {totalRowValue}
        </TotalRowBox>
    );
};

// create function for setting columns names in csv export
const columnHeaderFormatter = function (item: any) {
    if (item instanceof Highcharts.Axis && item.isXAxis) {
        return 'Date';
    } else return 'Net flows';
};

export const sortByPeriodAsc = (ETFFlowsData: Array<ETFDataFlows>) => {
    ETFFlowsData.sort((a, b) => (new Date(a.period_of) > new Date(b.period_of) ? 1 : -1)); // sort ETFFlowsData by as_of_date asc order
    return ETFFlowsData;
};

function getChartDataFor(ETFFlowsData: Array<ETFDataFlows>) {
    const result: ETFFlowsChartData = {
        xAxisDates: [],
        categoriesData: [],
    };

    ETFFlowsData.forEach((val) => {
        result.xAxisDates.push(getMomentObjectFrom(val.period_of));
        result.categoriesData.push([getMomentObjectFrom(val.period_of).toDate().getTime(), val.net_flows]);
    });

    return result;
}

export const getChartDataAllRanges = (cfraId: string): ETFDataAllRangesQueryResult => {
    const etfDataAllRanges: ETFDataAllRangesQueryResult = {};
    Object.values(DateRanges).forEach((dateRange) => {
        etfDataAllRanges[dateRange] = getETFFlows({
            cfraId: cfraId,
            dateRange: dateRange,
        });
    });
    return etfDataAllRanges;
};

export function extractETFDataForAllRanges(etfDataAllRangesQueryResult: ETFDataAllRangesQueryResult): ETFDataAllRanges {
    const etfDataAllRanges: ETFDataAllRanges = {};
    Object.keys(etfDataAllRangesQueryResult).forEach((key) => {
        if (!etfDataAllRangesQueryResult[key].data) etfDataAllRanges[key] = [];
        if (etfDataAllRangesQueryResult[key].data !== undefined) {
            etfDataAllRanges[key] = etfDataAllRangesQueryResult[key].data as ETFDataFlows[];
        }
    });
    return etfDataAllRanges;
}

export function determineDateRangeToShowByDefault(
    etfDataAllRanges: ETFDataAllRanges,
    mapFuncton: (element: ETFDataFlows) => boolean,
): DateRanges | undefined {
    for (let range in DateRanges) {
        const rangeData = etfDataAllRanges[DateRanges[range as keyof typeof DateRanges]];
        if (
            rangeData &&
            etfDataAllRanges[DateRanges[range as keyof typeof DateRanges]].map(mapFuncton).some((x) => x)
        ) {
            return DateRanges[range as keyof typeof DateRanges];
        }
    }
    return undefined;
}

export function getDateRangesWithDisabledButton(
    etfDataAllRanges: ETFDataAllRanges,
    mapFuncton: (element: ETFDataFlows) => boolean,
): Set<DateRanges> {
    const result = new Set<DateRanges>();

    const isEmptyRange = (dataList: Array<ETFDataFlows>) => !dataList.map(mapFuncton).some((x) => x);

    Object.keys(etfDataAllRanges).forEach((key) => {
        // add range to always disabled if have no data
        if (isEmptyRange(etfDataAllRanges[key])) result.add(key as DateRanges);
    });

    const orderedDateRanges: Array<DateRanges> = Object.values(DateRanges);

    orderedDateRanges.forEach((element, index) => {
        if (index === orderedDateRanges.length - 1) return;
        const currentDateRange = orderedDateRanges[index];
        const nextDateRange = orderedDateRanges[index + 1];
        const currentETFDataMinPeriodOf: Moment = moment.min(
            etfDataAllRanges[currentDateRange].map((element) => getMomentObjectFrom(element.period_of)),
        );

        // remove all data with period of greater than currentETFDataMinPeriodOf
        const filteredNextETFData = etfDataAllRanges[nextDateRange].filter((element) => {
            return getMomentObjectFrom(element.period_of).isBefore(currentETFDataMinPeriodOf);
        });

        // add next range to always disabled if it have no additional data in compairing with current range
        if (isEmptyRange(filteredNextETFData)) result.add(nextDateRange as DateRanges);
    });

    return result;
}

export default function ETFFlows({ companyData, etfDataAllRangesQueryResult }: ETFFlowsProps) {
    const [periodSelected, setPeriodSelected] = useState<DateRanges>();

    // is data for any of ranges still loading, show loading element
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map((element) => element.isLoading)
            .some((x) => x)
    ) {
        return <ETFCard isLoading={true} />;
    }

    const chartTitle = 'Fund Flows';
    const chartDescription = 'Total fund flows across all ETFs';

    // return EmptyCard if no data
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map(
                (element) =>
                    Boolean(element) &&
                    Boolean(element.data) &&
                    Array.isArray(element.data) &&
                    element.data.length > 0 &&
                    element.data.some((obj) => Number.isFinite(obj.net_flows)),
            )
            .every((x) => !x)
    ) {
        return <ETFEmptyCard cardLabel={chartTitle}></ETFEmptyCard>;
    }

    const etfDataAllRanges = extractETFDataForAllRanges(etfDataAllRangesQueryResult);

    const mapFunction = (element: ETFDataFlows) => Number.isFinite(element.net_flows);
    const defaultDateRange = determineDateRangeToShowByDefault(etfDataAllRanges, mapFunction);

    // show nothing if no data for all the ranges
    if (!defaultDateRange) return <ETFEmptyCard cardLabel={chartTitle}></ETFEmptyCard>;
    if (periodSelected === undefined) setPeriodSelected(defaultDateRange);
    // if no periodSelected set - show loading;
    if (periodSelected === undefined) return <ETFCard isLoading={true} />;

    const ETFFlowsData = sortByPeriodAsc(etfDataAllRanges[periodSelected]);
    const chartData = getChartDataFor(ETFFlowsData) as ETFFlowsChartData;
    const xAxisDates = chartData.xAxisDates;
    const categoriesData = chartData.categoriesData;
    const roundedTick = getTickIntervalFrom(categoriesData.map((value) => value[1]));

    const tooltipFormatter = function (this: Highcharts.TooltipFormatterContextObject) {
        const date = getDateForTooltipFormatter(this, periodSelected);

        return getTooltipHTML(date, `Fund Flow: ${formatFlowsNumber({ value: this.y as number })}`);
    };

    const exportFileName = `fund-flows-chart-${companyData.ticker}-${companyData.exchange}-${periodSelected}`;

    const [minTick, maxTick]: [number, number] = getMinAndMaxTick(
        roundedTick,
        categoriesData.map((value) => value[1]),
        true,
    );

    return (
        <ETFCard>
            <StyledFlowsChart
                series={categoriesData}
                title={chartTitle}
                subTitle={chartDescription}
                ticks={{ min: minTick, max: maxTick, tickerInterval: roundedTick }}
                tooltipFormatter={tooltipFormatter}
                columnHeaderFormatter={columnHeaderFormatter}
                periodSelected={periodSelected}
                xAxisDates={xAxisDates}
                exportFileName={exportFileName}
                setPeriodSelected={setPeriodSelected}
                dateRangesWithDisabledButton={getDateRangesWithDisabledButton(etfDataAllRanges, mapFunction)}
            />
            <Grid item xs={12}>
                {getTotalRow(
                    periodSelected,
                    categoriesData.map((value) => value[1]),
                )}
            </Grid>
        </ETFCard>
    );
}
