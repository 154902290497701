import { ETFCard, ETFEmptyCard } from 'components/layout';
import { getLevel2Flows } from 'features/marketTrends/api/market';
import { HomeProps, MarketTrend } from 'features/marketTrends/types/market';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { ValueTypes } from 'utils';
import { formatPercentages } from 'utils/valuesFormatter';
import { getColumnHeaderFormatter, ThemesAndFactorsChart, xAxisColumnName } from './shared/ThemesAndFactors';

export default function ETFFlowsToAssetThemesAndFactorsChart({ details = true }: HomeProps) {
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();

    // getting UseQueryResult object with data for desc order
    const descDataQueryResult = getLevel2Flows({
        sortDirection: 'desc',
    });
    // getting UseQueryResult object with data for asc order
    const ascDataQueryResult = getLevel2Flows({
        sortDirection: 'asc',
    });

    // show card loading if data still loading
    if (descDataQueryResult.isLoading || ascDataQueryResult.isLoading) {
        return <ETFCard isLoading={descDataQueryResult.isLoading || ascDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Flows to Assets: Themes & Factors (3 Months)';
    // Show Nothing if no data
    if (
        !(
            descDataQueryResult.data &&
            descDataQueryResult.data.length > 0 &&
            ascDataQueryResult.data &&
            ascDataQueryResult.data.length > 0
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;

    // cut off UseQueryResult attributes
    let descData = descDataQueryResult.data;
    let ascData = ascDataQueryResult.data;

    const yAxisColumnName = 'Flows to Assets Ratio';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(xAxisColumnName, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Numeral);

    return (
        <ETFCard containerStyles={{ paddingBottom: '8px' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={'ETF categories with the highest and lowest flows / assets ratio in the trailing 3 months.'}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter(yAxisColumnName)}
                exportFileName={'flows-to-assets-themes-and-factors-3-months'}
                detailsPathName={details ? MarketTrend.FlowsToAssetsDetails : undefined}
                subChartsProps={[
                    {
                        chartHeader: 'Best Performing',
                        categories: descData.map((value) => value.level_2_name),
                        categoriesData: descData.map((value) => formatPercentages(value.flow_to_asset_ratio)),
                        asOfDates: descData.map((value) => value.as_of_date),
                        chartRefObject: highestChartRef,
                        chartRefSetter: setHighestChartRef,
                        columnsToFormatting: columnsToFormatting,
                    },
                    {
                        chartHeader: 'Worst Performing',
                        categories: ascData.map((value) => value.level_2_name),
                        categoriesData: ascData.map((value) => formatPercentages(value.flow_to_asset_ratio)),
                        asOfDates: ascData.map((value) => value.as_of_date),
                        reverseYaxis: true,
                        chartRefObject: lowestChartRef,
                        chartRefSetter: setLowestChartRef,
                        columnsToFormatting: columnsToFormatting,
                    },
                ]}
            />
        </ETFCard>
    );
}
