import { SxProps, Theme } from '@mui/material/styles';
import { ETFInfoButton } from 'components/ETFButton';
import ETFModal from 'components/ETFModal';
import { useState } from 'react';

type Props = {
    labels: {
        title: string;
        content: string;
    };
    sx?: SxProps<Theme>;
};

export function ETFInfo({ labels }: Props) {
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => setOpenModal(false);
    const handleOpen = () => setOpenModal(true);
    return (
        <>
            <ETFInfoButton onClick={handleOpen} />
            <ETFModal openModal={openModal} handleClose={handleClose} labels={{ ...labels }} />
        </>
    );
}
