import { useLocation, useRoutes } from 'react-router-dom';
// Pattern with child routes
import { HomeRoutes } from 'features/home';
// Pattern with no child routes
import ErrorBoundary from 'components/fallbacks/ErrorBoundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import { ETF } from 'features/etfData';
import { Footer } from 'features/Footer';
import { MarketTrendsRoutes } from 'features/marketTrends/routes/MarketTrendsRoutes';
import { TopNavigation } from 'features/topNavigation';
import { useEffect } from 'react';
import { isLoggedIn, useAuth } from '../lib/authentication';

export default function AppRoutes() {
    const commonRoutes = [
        { path: '*', element: <HomeRoutes /> },
        { path: '/home/*', element: <HomeRoutes /> },
        { path: '/etf/ticker/:ticker/exchange/:exchange', element: <ETF /> },
        { path: '/etf/id/:id', element: <ETF /> },
        { path: '/market-trends/*', element: <MarketTrendsRoutes /> },
    ];

    const element = useRoutes([...commonRoutes]);
    const location = useLocation();
    const { user } = useAuth();

    useEffect(() => {
        if (user) isLoggedIn();
    }, [location, user]);

    if (!user) return null;

    return (
        <div>
            <TopNavigation />
            {/* Error Boundary Ensures We never lose the Top Navigation if an error is thrown */}
            <ErrorBoundary FallbackComponent={ErrorFallback}>{element}</ErrorBoundary>
            <Footer />
        </div>
    );
}
