import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { ETFCard, ETFEmptyCard } from 'components/layout';
import { getLevel2Performance } from 'features/marketTrends/api/market';
import { HomeProps, MarketTrend } from 'features/marketTrends/types/market';
import { ETFPerformanceThemesAndFactorsData } from 'features/marketTrends/types/research';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { formatPercentages, PerformanceDateRanges, ValueTypes } from 'utils';
import { getColumnHeaderFormatter, ThemesAndFactorsChart, xAxisColumnName } from './shared/ThemesAndFactors';

const orderFields = new Map([
    ['YTD', 'split_and_dividend_ytd_return'],
    ['1M', 'return_split_and_dividend_one_month'],
    ['3M', 'return_split_and_dividend_three_month'],
    ['1Y', 'return_split_and_dividend_one_year'],
]);

const categories = [
    { key: '', value: 'Asset Class' },
    { key: 'CFRA Category 1', value: 'Category 1' },
    { key: 'CFRA Category 2', value: 'Category 2', isdefault: true },
];

const localStorageID ="market-trends-etf-performance-themes-and-factors-"

export default function ETFPerformanceThemesAndFactors({ details = true }: HomeProps) {
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();

    const [currentActiveTab, setCurrentActiveTab] = useLocalStorage(
        localStorageID + 'active-tab',
        categories.findIndex((item) => item.isdefault),
    );
    const [currentPeriod, setCurrentPeriod] = useLocalStorage(localStorageID + 'active-perod', '3M');

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentActiveTab(newValue);
    };
    const handlePeriodChange = (event: any) => {
        setCurrentPeriod(event.target.value as string);
    };

    // getting UseQueryResult object with data for desc order
    const descDataQueryResult = getLevel2Performance({
        sortDirection: 'desc',
        orderBy: orderFields.get(currentPeriod),
    });
    // getting UseQueryResult object with data for asc order
    const ascDataQueryResult = getLevel2Performance({
        sortDirection: 'asc',
        orderBy: orderFields.get(currentPeriod),
    });

    // show card loading if data still loading
    if (descDataQueryResult.isLoading || ascDataQueryResult.isLoading) {
        return <ETFCard isLoading={descDataQueryResult.isLoading || ascDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Themes & Factors: Performance';
    // Show Nothing if no data
    if (
        !(
            descDataQueryResult.data &&
            descDataQueryResult.data.length > 0 &&
            ascDataQueryResult.data &&
            ascDataQueryResult.data.length > 0
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;

    // cut off UseQueryResult attributes, extract only KeyAssetClasses data
    let descData = descDataQueryResult.data;
    let ascData = ascDataQueryResult.data;

    const yAxisColumnName = 'Performance';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(xAxisColumnName, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Numeral);

    const mapFunction = (value: ETFPerformanceThemesAndFactorsData) =>
        formatPercentages(value[orderFields.get(currentPeriod) as keyof ETFPerformanceThemesAndFactorsData] as number);

    return (
        <ETFCard containerStyles={{ paddingBottom: '8px' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={'View the highest and lowest performing ETF categories for different periods.'}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter(yAxisColumnName)}
                exportFileName={'performance-themes-and-factors-3-months'}
                detailsPathName={details ? MarketTrend.PerformanceDetails : undefined}
                subChartsProps={[
                    {
                        chartHeader: 'Best Performing',
                        categories: descData.map((value) => value.level_2_name),
                        categoriesData: descData.map(mapFunction),
                        asOfDates: descData.map((value) => value.as_of_date),
                        tickers: descData.map((value) => value.composite_ticker),
                        chartRefObject: highestChartRef,
                        chartRefSetter: setHighestChartRef,
                        columnsToFormatting: columnsToFormatting,
                    },
                    {
                        chartHeader: 'Worst Performing',
                        categories: ascData.map((value) => value.level_2_name),
                        categoriesData: ascData.map(mapFunction),
                        asOfDates: ascData.map((value) => value.as_of_date),
                        tickers: descData.map((value) => value.composite_ticker),
                        reverseYaxis: true,
                        chartRefObject: lowestChartRef,
                        chartRefSetter: setLowestChartRef,
                        columnsToFormatting: columnsToFormatting,
                    },
                ]}
                chartFilters={{
                    data: {
                        periods: Object.values(PerformanceDateRanges),
                        categories: categories,
                    },
                    eventHandlers: {
                        tabChange: handleTabChange,
                        periodChange: handlePeriodChange,
                    },
                    selections: {
                        period: currentPeriod,
                        tab: currentActiveTab,
                    },
                }}
            />
        </ETFCard>
    );
}
