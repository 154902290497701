import { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { fontFamilies } from 'utils/fonts';
import { formatValue, ValueTypes } from 'utils/valuesFormatter';
import './agGrid.scss';

type ColumnDefProps = {
    field: string;
    filter?: boolean;
    resizable?: boolean;
    flex?: number;
    maxWidth?: number;
};

export type columnDefs = Array<ColDef<ColumnDefProps> | ColGroupDef<ColumnDefProps>> | null;

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

export function AgGrid({
    columnDefs,
    rowsData,
    numberOfRowsToShow,
}: {
    columnDefs: columnDefs;
    rowsData: Array<any>;
    numberOfRowsToShow?: number;
}) {
    const gridRef = useRef<AgGridReact<ColumnDefProps>>(null);
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
        (): ColDef => ({
            sortable: true,
            filter: true,
            resizable: true,
            autoHeight: true,
            maxWidth: 350,
        }),
        [],
    );

    const defaultRowHeight = 46;

    const styles = { fontFamily: fontFamilies.GraphikRegular, fontSize: 15 };

    const autoSizeAll = useCallback(() => {
        if (!gridRef.current) return;
        const right = gridRef.current.api.getHorizontalPixelRange().right;

        gridRef.current?.columnApi.autoSizeAllColumns(true);
        
        const allColumnsWidth = gridRef.current.columnApi
            .getColumns()
            ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0);
        if (!allColumnsWidth) return;
        const haveFreeSpace = right > allColumnsWidth;

        function setMaxWidthForAllColumnsTo(maxWidth: number) {
            const columnDefs = gridRef.current?.api.getColumnDefs();
            if (!columnDefs) return;
            gridRef.current?.api.setColumnDefs([
                ...columnDefs.map((columnDef) => {
                    (columnDef as ColumnDefProps).maxWidth = maxWidth;
                    return { ...columnDef };
                }),
            ]);
        }

        gridRef.current?.columnApi.autoSizeAllColumns(!haveFreeSpace);
        setMaxWidthForAllColumnsTo(right);
    }, []);
    
    return (
        <>
            <div style={{ width: '100%' }} className='cfra-ag-grid'>
                <div
                    className='ag-theme-alpine'
                    style={{
                        height: numberOfRowsToShow ? (numberOfRowsToShow + 1) * defaultRowHeight : undefined,
                    }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowsData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true}
                        rowSelection='single'
                        getRowHeight={() => defaultRowHeight}
                        headerHeight={defaultRowHeight}
                        rowStyle={styles}
                        containerStyle={styles}
                        onFirstDataRendered={() => setTimeout(() => autoSizeAll(), 100)} // onFirstDataRendered not always works without setTimeout
                        domLayout={numberOfRowsToShow ? undefined : 'autoHeight'}
                    />
                </div>
            </div>
        </>
    );
}
