import {ETFCircleButton } from 'components/layout';
import React, { useState } from 'react';
import { DateRanges } from 'utils';

export type ETFButtonsPannelButtonProps = {
    name: DateRanges;
    callback: (selectedPeriod: DateRanges) => void;
    disabled: boolean;
};

export type ETFButtonsPannelProps = {
    buttonsConfig: Array<ETFButtonsPannelButtonProps>;
    defaultPeriodSelected?: string;
};

export function ETFButtonsPannel({ buttonsConfig, defaultPeriodSelected }: ETFButtonsPannelProps): JSX.Element {
    const [clickedButton, setClickedButton] = useState(defaultPeriodSelected ? defaultPeriodSelected : '');

    const buttons: Array<React.ReactElement> = [];

    buttonsConfig.forEach((buttonConfig) => {
        const buttonCallback = (event: React.MouseEvent<HTMLButtonElement>) => {
            setClickedButton(event.currentTarget.name);
            buttonConfig.callback(event.currentTarget.name as DateRanges);
        };

        const isSelected = (): boolean => clickedButton === buttonConfig.name;

        buttons.push(
            <ETFCircleButton
                text={buttonConfig.name}
                disabled={buttonConfig.disabled}
                callback={buttonCallback}
                isSelected={isSelected()}
            />,
        );
    });

    return <>{React.Children.toArray(buttons)}</>;
}
