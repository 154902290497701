import { getMinAndMaxTick, getTickIntervalFrom } from 'components/Chart/Chart';
import { formatFlowsNumber, getDateForTooltipFormatter, StyledFlowsChart } from 'components/Chart/Flows';
import { DateValuePair, NumberOrNull } from 'components/Chart/types';
import { ETFCard, ETFEmptyCard } from 'components/layout';
import Highcharts from 'highcharts/highstock';
import { Moment } from 'moment';
import { useState } from 'react';
import { DateRanges, getMomentObjectFrom } from 'utils';
import { ETFDataFlows, ETFFlowsProps } from '../types/research';
import {
    determineDateRangeToShowByDefault,
    extractETFDataForAllRanges,
    getDateRangesWithDisabledButton,
    sortByPeriodAsc,
} from './ETFFlows';

type ETFFlowsVSMarketMovementChartData = {
    xAxisDates: Array<Moment>;
    categoriesData: Array<NumberOrNull>;
    series: Array<{
        data: Array<DateValuePair>;
        type: 'column' | 'spline';
        name?: string;
    }>;
};

// create function for setting columns names in csv export
const columnHeaderFormatter = function (item: any) {
    if (item instanceof Highcharts.Axis && item.isXAxis) {
        return 'Date';
    }
    return item.name;
};

function getChartDataFor(ETFFlowsData: Array<ETFDataFlows>) {
    const result: ETFFlowsVSMarketMovementChartData = {
        xAxisDates: [],
        categoriesData: [],
        series: [
            {
                data: [],
                type: 'column',
                name: 'Market Movement',
            },
            {
                data: [],
                type: 'column',
                name: 'Flows',
            },
            {
                data: [],
                type: 'spline',
                name: 'Asset Change',
            },
        ],
    };

    ETFFlowsData.forEach((value) => {
        result.xAxisDates.push(getMomentObjectFrom(value.period_of));
        result.categoriesData = result.categoriesData.concat([
            value.net_flows,
            value.asset_change,
            value.market_value_change,
        ]);
        const getDateValuePair = (date: string, value: number | null) =>
            [getMomentObjectFrom(date).toDate().getTime(), value] as DateValuePair;
        result.series[0].data.push(getDateValuePair(value.period_of, value.market_value_change));
        result.series[1].data.push(getDateValuePair(value.period_of, value.net_flows));
        result.series[2].data.push(
            getDateValuePair(value.period_of, value.asset_change === null ? 0 : value.asset_change),
        );
    });

    return result;
}

export default function ETFFlowsVSMarketMovement({ companyData, etfDataAllRangesQueryResult }: ETFFlowsProps) {
    const [periodSelected, setPeriodSelected] = useState<DateRanges>();

    // is data for any of ranges still loading, show loading element
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map((element) => element.isLoading)
            .some((x) => x)
    ) {
        return <ETFCard isLoading={true} />;
    }

    const chartTitle = 'Fund Flows vs Market Movement';
    const chartDescription = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';

    // return EmptyCard if no data
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map(
                (element) =>
                    Boolean(element) &&
                    Boolean(element.data) &&
                    Array.isArray(element.data) &&
                    element.data.length > 0 &&
                    element.data.some(
                        (obj) =>
                            Number.isFinite(obj.net_flows) ||
                            Number.isFinite(obj.asset_change) ||
                            Number.isFinite(obj.market_value_change),
                    ),
            )
            .every((x) => !x)
    ) {
        return <ETFEmptyCard cardLabel={chartTitle}></ETFEmptyCard>;
    }

    const etfDataAllRanges = extractETFDataForAllRanges(etfDataAllRangesQueryResult);

    const mapFunction = (element: ETFDataFlows) =>
        Number.isFinite(element.net_flows) ||
        Number.isFinite(element.asset_change) ||
        Number.isFinite(element.market_value_change);
    const defaultDateRange = determineDateRangeToShowByDefault(etfDataAllRanges, mapFunction);

    // show nothing if no data for all the ranges
    if (!defaultDateRange) return <ETFEmptyCard cardLabel={chartTitle}></ETFEmptyCard>;
    if (periodSelected === undefined) setPeriodSelected(defaultDateRange);
    // if no periodSelected set - show loading;
    if (periodSelected === undefined) return <ETFCard isLoading={true} />;

    const ETFFlowsData = sortByPeriodAsc(etfDataAllRanges[periodSelected]);
    const chartData = getChartDataFor(ETFFlowsData as Array<ETFDataFlows>) as ETFFlowsVSMarketMovementChartData;
    const xAxisDates = chartData.xAxisDates;
    const categoriesData = chartData.categoriesData;
    const series = chartData.series;
    const roundedTick = getTickIntervalFrom(categoriesData);

    const tooltipFormatter = function (this: any) {
        const date = getDateForTooltipFormatter(this, periodSelected);
        let tooltip: string = '';

        if (this.series.index === 0) {
            tooltip = `<tr><th><div>${date}</div></th></tr><tr><td>`;
        }
        return (
            tooltip +
            `<div class="leftPadding rightPadding"><span>${this.series.name}: ${formatFlowsNumber({
                value: this.y,
            })}</span></div>` +
            (this.series.index === 2 ? '</td></tr>' : '')
        );
    };

    const [minTick, maxTick]: [number, number] = getMinAndMaxTick(roundedTick, categoriesData, true);

    const exportFileName = `fund-flows-vs-market-movement-chart-${companyData.ticker}-${companyData.exchange}-${periodSelected}`;

    return (
        <ETFCard>
            <StyledFlowsChart
                series={series}
                title={chartTitle}
                subTitle={chartDescription}
                ticks={{ min: minTick, max: maxTick, tickerInterval: roundedTick }}
                tooltipFormatter={tooltipFormatter}
                columnHeaderFormatter={columnHeaderFormatter}
                periodSelected={periodSelected}
                xAxisDates={xAxisDates}
                exportFileName={exportFileName}
                setPeriodSelected={setPeriodSelected}
                dateRangesWithDisabledButton={getDateRangesWithDisabledButton(etfDataAllRanges, mapFunction)}
                enableLegend={true}
            />
        </ETFCard>
    );
}
