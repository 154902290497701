import { ETFHeaderContainer, HeaderName } from 'components/layout';
import { ETFPageContainer } from 'components/layout/ETFPageContainer';
import { ETFTabContainer } from 'components/layout/ETFTabContainer';
import { Outlet, useLocation, Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { pathNamesMapping } from '../routes/MarketTrendsRoutes';
import Typography from 'components/dataDisplay/Typography';
import { fontFamilies } from 'utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { appTheme } from 'components/themes/theme';

export function MarketTrends() {
    const [title, setTitle] = React.useState('');
    const pathNames = useLocation().pathname.split('/');

    let theme = createTheme(appTheme, {
        components: {
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        paddingTop: '12px',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '13px',
                        display: 'flex',
                        color: '#0B2958',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        fontFamily: fontFamilies.GraphikRegular,
                        fontSize: '13px',
                        color: '#3078B5',
                        display: 'flex',
                    },
                },
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <ETFHeaderContainer shadow sx={{ zIndex: 1100, position: 'sticky', top: '72px' }}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        {pathNames.map((value: string, index: number) => {
                            const to = `/${pathNames.slice(index ? 1 : 0, index + 1).join('/')}`;
                            if (index + 1 === pathNames.length)
                                return <Typography key={index}>{pathNamesMapping[value]}</Typography>;

                            return (
                                <Link underline='hover' component={RouterLink} to={to} key={index}>
                                    {pathNamesMapping[value]}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                    <HeaderName>{title}</HeaderName>
                </ETFHeaderContainer>
            </ThemeProvider>
            <ETFPageContainer>
                <ETFTabContainer
                    style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justifyContent: 'center',
                    }}>
                    <Outlet context={[title, setTitle]} />
                </ETFTabContainer>
            </ETFPageContainer>
        </>
    );
}
