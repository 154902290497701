import { BarChart } from 'components/Chart';
import { getTooltipHTML } from 'components/Chart/Options';
import { ETFCard, ETFEmptyCard } from 'components/layout';
import Highcharts from 'highcharts/highstock';
import { isCloseOrEqualToZero, MaturityExposureRanges } from 'utils';
import { formatPercentages } from 'utils/valuesFormatter';
import { getEtfHoldingsAndExposure } from '../api/etfDetailsData';
import { EtfDataMaturityExposure, ETFDetailsParams } from '../types/research';

export default function MaturityExposure({ cfraId, companyData }: { cfraId: string; companyData: ETFDetailsParams }) {
    // getting UseQueryResult object with data for maturityExposure chart
    const maturityExposureDataQueryResult = getEtfHoldingsAndExposure<EtfDataMaturityExposure>({
        dataType: 'maturity-exposure',
        cfraId: cfraId,
    });
    // show card loading if data still loading
    if (maturityExposureDataQueryResult.isLoading) {
        return <ETFCard isLoading={maturityExposureDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Maturity Exposure';
    // return EmptyCard if no data
    if (
        !(
            maturityExposureDataQueryResult.data &&
            maturityExposureDataQueryResult.data.maturity &&
            maturityExposureDataQueryResult.data.maturity.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    // cut off UseQueryResult attributes, extract only maturityExposure data
    let maturityExposureData = maturityExposureDataQueryResult.data.maturity;
    // create enum with MaturityExposure time ranges for display in UI
    enum MaturityExposureDisplayRanges {
        LessThanOneYear = '<1 Year',
        ShortFromOneToThreeYears = 'Short (1-3 Years)',
        IntermediateFromThreeToTenYears = 'Intermediate (3-10 Years)',
        LongTenPlusYears = 'Long (10+ Years)',
        Other = 'Other',
        Unclassified = 'Unclassified',
    }
    // create ordered list of time ranges
    const orderedMaturityRanges: Array<MaturityExposureDisplayRanges> = [
        MaturityExposureDisplayRanges.LessThanOneYear,
        MaturityExposureDisplayRanges.ShortFromOneToThreeYears,
        MaturityExposureDisplayRanges.IntermediateFromThreeToTenYears,
        MaturityExposureDisplayRanges.LongTenPlusYears,
        MaturityExposureDisplayRanges.Other,
        MaturityExposureDisplayRanges.Unclassified,
    ];
    // create matching between time ranges from API and time ranges to display in UI
    const dataPointsFieldNameToDisplayName: Record<MaturityExposureRanges, MaturityExposureDisplayRanges> = {
        [MaturityExposureRanges.LessThanOneYear]: MaturityExposureDisplayRanges.LessThanOneYear,
        [MaturityExposureRanges.ShortFromOneToThreeYears]: MaturityExposureDisplayRanges.ShortFromOneToThreeYears,
        [MaturityExposureRanges.IntermediateFromThreeToTenYears]:
            MaturityExposureDisplayRanges.IntermediateFromThreeToTenYears,
        [MaturityExposureRanges.LongTenPlusYears]: MaturityExposureDisplayRanges.LongTenPlusYears,
        [MaturityExposureRanges.Other]: MaturityExposureDisplayRanges.Other,
        [MaturityExposureRanges.Unclassified]: MaturityExposureDisplayRanges.Unclassified,
    };
    // create list with maturity time ratings which present in response from API
    const availableMaturityRanges: MaturityExposureRanges[] = maturityExposureData.map((element) => element.maturity);
    // find maturity ranges which not present in response from API, but have to be in result chart with 0%
    // and add them to the list with 0% weighting
    (Object.keys(dataPointsFieldNameToDisplayName) as Array<MaturityExposureRanges>).forEach((element) => {
        if (!availableMaturityRanges.includes(element))
            maturityExposureData.push({
                agg_weighting: 0,
                maturity: element,
                maturity_id: 0,
                constituent_types: [],
            });
    });
    // sort the output list with the exact order as in the orderedMaturityRanges template
    maturityExposureData.sort((a, b) => {
        return (
            orderedMaturityRanges.lastIndexOf(dataPointsFieldNameToDisplayName[a.maturity]) -
            orderedMaturityRanges.lastIndexOf(dataPointsFieldNameToDisplayName[b.maturity])
        );
    });
    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: any, key: any) {
        if (item instanceof Highcharts.Axis && item.isXAxis) {
            return 'Maturity range';
        } else return '% Held';
    };

    maturityExposureData = maturityExposureData.filter((value) => {
        // remove Other and Unclassified items with too small weighting
        if (value.maturity === MaturityExposureRanges.Other || value.maturity === MaturityExposureRanges.Unclassified) {
            return !isCloseOrEqualToZero(value.agg_weighting);
        }
        return true;
    });

    const tooltipFormatter = function (this: any) {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        const getOtherToolTipRow = () =>
            maturityExposureData
                .filter((value) => value.maturity === MaturityExposureRanges.Other)[0]
                .constituent_types.map(
                    (value) =>
                        `<span>${value.type_name}: ${getFormattedValue(
                            formatPercentages(value.agg_weighting),
                        )}%</span>`,
                );

        return getTooltipHTML(
            this.category,
            this.category === MaturityExposureRanges.Other
                ? getOtherToolTipRow()
                : [`${getFormattedValue(this.y as number)}%`],
        );
    };

    const categories: Array<string> = [];
    const categoriesData: Array<number> = [];
    // fill the list with the weighting values and maturity ranges
    maturityExposureData.forEach((val) => {
        categories.push(dataPointsFieldNameToDisplayName[val.maturity]);
        categoriesData.push(val.agg_weighting);
    });

    return (
        <ETFCard>
            <BarChart
                categories={categories}
                series={{ data: categoriesData }}
                columnHeaderFormatter={columnHeaderFormatter}
                title={cardTitle}
                subTitle='The Current Market Value of all Positions in any given industry sector'
                exportFileName={`maturity-exposure-chart-${companyData.ticker}-${companyData.exchange}`}
                tooltipFormatter={tooltipFormatter}
                useHTML={true}
            />
        </ETFCard>
    );
}
