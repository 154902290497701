import {
    getSeries,
    getUniqueKeys,
    HistoricalStackedPercentage,
    sortSeriesByList,
} from 'components/Chart/HistoricalStackedPercentage';
import { getTooltipHTML } from 'components/Chart/Options';
import { ETFCard, ETFEmptyCard } from 'components/layout';
import { buildChartStacks } from 'utils';
import { calcPercentChange, formatPercentages, getExportingFileName } from 'utils/valuesFormatter';
import { getSectorExposureHistorical } from '../api/etfDetailsData';
import { ConstituentType, ETFDetailsParams, SectorExposureData } from '../types/research';

export default function SectorExposureHistorical({
    companyData,
    numberOfYears = 6,
}: {
    companyData: ETFDetailsParams;
    numberOfYears?: number;
}) {
    let sectorExposureHistoricalQueryResult = getSectorExposureHistorical({
        ticker: companyData.ticker,
        exchange: companyData.exchange,
        numberOfYears: numberOfYears,
    });

    if (sectorExposureHistoricalQueryResult.isLoading) {
        return <ETFCard isLoading={sectorExposureHistoricalQueryResult.isLoading} />;
    }
    const cardTitle = 'Sector Exposure Over-Time';
    // return EmptyCard if no data
    if (
        !(
            sectorExposureHistoricalQueryResult.data &&
            sectorExposureHistoricalQueryResult.data.sectors &&
            sectorExposureHistoricalQueryResult.data.sectors.length > 0 &&
            sectorExposureHistoricalQueryResult.data.sectors.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;

    let sectorExposureData: Array<SectorExposureData> = sectorExposureHistoricalQueryResult.data.sectors;
    const series = getSeries(sectorExposureData, ['gics_name', 'year_end'], 'agg_weighting').map((element) => {
        // change weightings format to percentage
        return { ...element, data: element.data.map((item) => formatPercentages(item)) };
    });
    const categories = getUniqueKeys(sectorExposureData, 'year_end');
    const constituentTypes: Record<string, Array<ConstituentType>> = sectorExposureData
        .filter((element) => element.constituent_types.length > 0)
        .reduce((accumulator: any, currentValue) => {
            accumulator[currentValue.year_end] = currentValue.constituent_types.map((element) => {
                return {
                    ...element,
                    // change constituent types weightings format to percentage
                    agg_weighting: formatPercentages(element.agg_weighting),
                };
            });
            return accumulator;
        }, {});

    sortSeriesByList(series, series.map((item) => item.name).concat(['Other', 'Unclassified']));

    const tooltipFormatter = function (this: any): string {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        const getOtherToolTipRow = () =>
            constituentTypes[this.category].map(
                (element) => `<span>${element.type_name}: ${getFormattedValue(element.agg_weighting)}%</span>`,
            );

        const getToolTipRow = (): Array<string> => {
            let prevPoint = this.x === 0 ? null : this.series.data[this.x - 1];
            let changePercentage: string = '';

            if (prevPoint?.y) {
                changePercentage = `<span><span class="dot" /></span><span>YoY % Change: ${calcPercentChange(
                    prevPoint.y,
                    this.y,
                    2,
                )}%</span>`;
            }

            return [
                `<span><span class="dot" style="background-color: ${this.series.color}"></span></span>` +
                    `<span>${this.series.name}: ${getFormattedValue(this.y as number)}%</span>`,
                changePercentage,
            ];
        };

        return getTooltipHTML(this.category, [
            ...getToolTipRow(),
            ...(this.series.name === 'Other' ? getOtherToolTipRow() : []),
        ]);
    };

    return (
        <ETFCard>
            <HistoricalStackedPercentage
                title={cardTitle}
                subTitle='Showing a breakdown of market sectors over time'
                series={buildChartStacks(series)}
                categories={categories}
                exportingFileName={getExportingFileName(cardTitle, companyData.ticker, companyData.exchange)}
                tooltipFormatter={tooltipFormatter}
            />
        </ETFCard>
    );
}
